/* stylelint-disable */
@mixin big-desktop {
  @media (max-width: (1399.98px)) {
    @content;
  }
}

@mixin for-desktop {
  @media (max-width: (1199.98px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (991.98px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (767.98px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (575.98px)) {
    @content;
  }
}

