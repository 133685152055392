.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #e7e4dfbe;
  box-shadow: 0px 6px 4px -2px rgba(34, 60, 80, 0.13);

  &__container {
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width:1433px) {
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }

  }

  @media (max-width:1433px) {
    background-color: var(--background-color);

  }
}
.logo {
  img {
    width: 100px;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  transition: transform .3s ease-in-out;

  &__list {
    display: flex;
    // align-content: flex-start;

    @media (max-width:1433px) {
      flex-direction: column;
      // align-items: center;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-left: 1rem;

      @media (max-width:1433px) {
        margin-left: 0;
      }
    }

    @media (max-width:1433px) {
      border-bottom: 1px dotted var(--accent-color);
      width: 100%;
    }
  }

  &__link {
    color: var(--accent-color);
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 700;

    @media (max-width:1433px) {
      display: block;
      padding: 20px 50px 20px 20px;
    }
  }

  @media (max-width:1433px) {
    position: fixed;
    z-index: 10;
    top: 106px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--background-color);
    overflow-y: auto;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    pointer-events: none;
    flex-direction: column;
    flex-grow: 0;
    justify-content: start;
    align-items: normal;
    margin-left: 0;

  }
}


.header-phone {
  color: var(--accent-color);
  padding: 10px 15px;

  font-weight: 700;
}

.header-call {
  padding: 40px 50px;
  background-color: var(--accent-color);
  color: var(--background-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 300;

  @media (max-width:1523px) {
    padding: 30px 25px;
  }

  @media (max-width:1433px) {
    margin-top: 2rem;
    text-align: center;
  }
}

.menu--active {
  transform: none;
  pointer-events: auto;
  transition: transform .3s ease-in-out;
}

.header-links {
  @media (max-width:1433px) {
    margin-top: 2rem;
    text-align: center;
  }
}
