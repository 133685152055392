.cake {
  &__container {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);
  }

  &__title {
    font-size: 42px;
    margin-bottom: 2rem;
    letter-spacing: -0.05em;
    line-height: 1;
    font-weight: 300;
  }

  &__wrapper {
    display: grid;
    // align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__col {
    text-align: right;
    font-size: 26px;
    p {
      margin-bottom: 2rem;
    }

    &:nth-child(2) {

      @include mobile {
        order: -1;
      }

    }

    @include mobile {
      text-align: left;
    }
  }
}
