.halls {
  &__container {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);
  }

  &__title {
    font-size: 42px;
    margin-bottom: 2rem;
    letter-spacing: -0.05em;
    line-height: 1;
    font-weight: 300;
  }
}
