:root {
  --font-family: "Playfair", serif;
  --content-width: 1700px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --background-color: #e7e4df;
  --accent-color: #283f2b;
  --padding-vertical: 6.25rem;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Playfair";
  src: url("../fonts/../fonts/PlayfairDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Playfair";
  src: url("../fonts/../fonts/PlayfairDisplay-Italic.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Playfair";
  src: url("../fonts/../fonts/PlayfairDisplay-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Playfair";
  src: url("../fonts/../fonts/PlayfairDisplay-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family);
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: var(--background-color);
  color: var(--accent-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.btn {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--background-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 300;
  border-radius: 0.25rem;
  padding: 30px 45px;
  width: auto;
  text-align: center;
  font-size: 16px;
}

p {
  margin: 0;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: var(--background-color);
}

.burger {
  color: var(--accent-color) !important;
  --burger-width: 30px;
  --burger-height: 30px;
  --burger-line-height: 2px;
  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: #000;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger::before {
  top: 0;
}
.burger::after {
  top: calc(100% - var(--burger-line-height));
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
@media (min-width: 1434px) {
  .burger {
    display: none;
  }
}

@media (max-width: 991.98px) {
  :root {
    --padding-vertical: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  :root {
    --padding-vertical: 1.563rem;
  }
}
.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(231, 228, 223, 0.7450980392);
  box-shadow: 0px 6px 4px -2px rgba(34, 60, 80, 0.13);
}
.header__container {
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 1433px) {
  .header__container {
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 1433px) {
  .header {
    background-color: var(--background-color);
  }
}

.logo img {
  width: 100px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  transition: transform 0.3s ease-in-out;
}
.nav__list {
  display: flex;
}
@media (max-width: 1433px) {
  .nav__list {
    flex-direction: column;
  }
}
.nav__item:not(:last-child) {
  margin-left: 1rem;
}
@media (max-width: 1433px) {
  .nav__item:not(:last-child) {
    margin-left: 0;
  }
}
@media (max-width: 1433px) {
  .nav__item {
    border-bottom: 1px dotted var(--accent-color);
    width: 100%;
  }
}
.nav__link {
  color: var(--accent-color);
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 1433px) {
  .nav__link {
    display: block;
    padding: 20px 50px 20px 20px;
  }
}
@media (max-width: 1433px) {
  .nav {
    position: fixed;
    z-index: 10;
    top: 106px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--background-color);
    overflow-y: auto;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    pointer-events: none;
    flex-direction: column;
    flex-grow: 0;
    justify-content: start;
    align-items: normal;
    margin-left: 0;
  }
}

.header-phone {
  color: var(--accent-color);
  padding: 10px 15px;
  font-weight: 700;
}

.header-call {
  padding: 40px 50px;
  background-color: var(--accent-color);
  color: var(--background-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 300;
}
@media (max-width: 1523px) {
  .header-call {
    padding: 30px 25px;
  }
}
@media (max-width: 1433px) {
  .header-call {
    margin-top: 2rem;
    text-align: center;
  }
}

.menu--active {
  transform: none;
  pointer-events: auto;
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 1433px) {
  .header-links {
    margin-top: 2rem;
    text-align: center;
  }
}

.hero-video {
  position: relative;
}
.hero-video__wrapper {
  height: 100vh;
}
.hero-video__wrapper::after {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2903536415) 0%, rgba(0, 0, 0, 0.5032387955) 100%);
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
.hero-video__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero__content {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 100%;
  transform: translate(50%, -50%);
  z-index: 3;
  color: var(--background-color);
}
.hero__title {
  display: block;
  font-size: 68px;
  line-height: 100%;
  margin-bottom: 2rem;
  font-weight: 400;
}
.hero__subtitle {
  font-size: 42px;
  font-style: italic;
}

.container__about {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}
@media (max-width: 575.98px) {
  .container__about {
    grid-template-columns: 1fr;
  }
}

.about__col {
  font-size: 26px;
}
.about__text:not(:last-child) {
  margin-bottom: 1rem;
}

.section-image {
  min-height: 100vh;
  height: 100%;
  background-image: url("../img/about/132.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gifts__container {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
}
.gifts__title {
  font-size: 42px;
  margin-bottom: 1rem;
  letter-spacing: -0.05em;
  line-height: 1;
  font-weight: 300;
}
.gifts__small {
  font-size: 1rem;
  margin-bottom: 2rem;
}
.gifts__wrapper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}
@media (max-width: 767.98px) {
  .gifts__wrapper {
    grid-template-columns: 1fr;
  }
}
.gifts__item {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px dotted var(--accent-color);
}
.gifts__text {
  font-size: 26px;
}

.bullet {
  background: var(--accent-color);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  display: block;
  padding-right: 1rem;
  margin-right: 2rem;
}

.giftsSwiper .swiper-slide {
  width: 50%;
}

.halls__container {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
}
.halls__title {
  font-size: 42px;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
  line-height: 1;
  font-weight: 300;
}

.menu__container {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
}
.menu__title {
  font-size: 42px;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
  line-height: 1;
  font-weight: 300;
}
.menu__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}
@media (max-width: 575.98px) {
  .menu__wrapper {
    grid-template-columns: 1fr;
  }
}
.menu__right {
  font-size: 26px;
}
.menu__right p {
  margin-bottom: 2rem;
}

.cake__container {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
}
.cake__title {
  font-size: 42px;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
  line-height: 1;
  font-weight: 300;
}
.cake__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}
@media (max-width: 575.98px) {
  .cake__wrapper {
    grid-template-columns: 1fr;
  }
}
.cake__col {
  text-align: right;
  font-size: 26px;
}
.cake__col p {
  margin-bottom: 2rem;
}
@media (max-width: 575.98px) {
  .cake__col:nth-child(2) {
    order: -1;
  }
}
@media (max-width: 575.98px) {
  .cake__col {
    text-align: left;
  }
}

.weddings__container {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
}
.weddings__title {
  font-size: 42px;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
  line-height: 1;
  font-weight: 300;
}

.weddingsSlide img {
  width: 100%;
  height: auto;
  display: block;
}

.swiper-pagination {
  color: var(--background-color);
}

.contacts__container {
  padding-top: var(--padding-vertical);
  padding-bottom: var(--padding-vertical);
}
.contacts__title {
  font-size: 42px;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
  line-height: 1;
  font-weight: 300;
}
.contacts__title span {
  color: #818982;
}

.contact-form {
  display: block;
  position: relative;
}

.form-group {
  align-items: flex-start;
  display: flex;
  padding: 2rem 0;
  position: relative;
}
@media (max-width: 575.98px) {
  .form-group {
    flex-direction: column;
  }
}
.form-group:not(:last-child) {
  border-bottom: 1px dotted var(--accent-color);
}
.form-group label {
  font-size: 26px;
  padding-left: 11.1111111111vw;
  width: 33.3333333333vw;
}
@media (max-width: 575.98px) {
  .form-group label {
    padding-left: 0;
    margin-bottom: 1rem;
  }
}
.form-group input {
  flex: 1;
  width: auto;
  font-size: 26px;
  background: none;
  border: 0;
  border-radius: 0;
  color: #3c4e3d;
  outline: none;
}
.form-group textarea {
  background: none;
  border: 0;
  border-radius: 0;
  color: #3c4e3d;
  display: block;
  outline: none;
  resize: none;
  font-size: 26px;
  width: auto;
  overflow-y: hidden;
}

.contacts .bullet {
  position: absolute;
  left: 0;
  right: auto;
  top: 2.5rem;
}
@media (max-width: 575.98px) {
  .contacts .bullet {
    display: none;
  }
}

.freeDates p {
  text-align: center;
  font-size: 42px;
}

@media (max-width: 575.98px) {
  .freeDates p {
    text-align: center;
    font-size: 18px;
  }
  .form-group {
    align-items: normal;
  }
}
.footer {
  background-color: hsla(39, 17%, 80%, 0.3);
}
.footer__container {
  padding-top: 3.813rem;
  padding-bottom: 3.813rem;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 575.98px) {
  .footer__container {
    grid-template-columns: 1fr;
  }
}
.footer__col h3 {
  margin-bottom: 2rem;
}
.footer__col address {
  font-style: normal;
}
.footer__name {
  margin-bottom: 1rem;
}
@media (max-width: 575.98px) {
  .footer__col:not(:last-child) {
    padding-bottom: 2rem;
    border-bottom: 1px dotted var(--accent-color);
  }
}
.footer__item:not(:last-child) {
  margin-bottom: 1rem;
}
.footer__link {
  color: var(--accent-color);
  padding: 10px 0;
}