.gifts {
  &__container {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);

  }

  &__title {
    font-size: 42px;
    margin-bottom: 1rem;
    letter-spacing: -0.05em;
    line-height: 1;
    font-weight: 300;
  }

  &__small {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  &__wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;

    @include small-tablet {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px dotted var(--accent-color);
  }

  &__text {
    font-size: 26px;
  }

}

.bullet {
  background: var(--accent-color);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  display: block;
  padding-right: 1rem;
  margin-right: 2rem;
}

.giftsSwiper {
  .swiper-slide {
    width: 50%;
  }
}
