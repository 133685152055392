.contacts {
  &__container {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);
  }

  &__title {
    font-size: 42px;
    margin-bottom: 2rem;
    letter-spacing: -0.05em;
    line-height: 1;
    font-weight: 300;

    span {
      color: #818982;
    }
  }
}

.contact-form {
  display: block;
  position: relative;
}

.form-group {
  align-items: flex-start;
  display: flex;
  padding: 2rem 0;
  position: relative;

  @include mobile {
    flex-direction: column;
  }

  &:not(:last-child) {
    border-bottom: 1px dotted var(--accent-color);
  }

  label {
    font-size: 26px;
    padding-left: 11.1111111111vw;
    width: 33.3333333333vw;

    @include mobile {
      padding-left: 0;
      margin-bottom: 1rem;
    }
  }

  input {
    flex: 1;
    width: auto;
    font-size: 26px;
    background: none;
    border: 0;
    border-radius: 0;
    color: #3c4e3d;
    outline: none;
  }

  textarea {
    background: none;
    border: 0;
    border-radius: 0;
    color: #3c4e3d;
    display: block;
    outline: none;
    resize: none;
    font-size: 26px;
    width: auto;
    overflow-y: hidden;
  }
}

.contacts .bullet {
  position: absolute;
  left: 0;
  right: auto;
  top: 2.5rem;

  @include mobile {
    display: none;
  }
}
