.menu {
  &__container {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);
  }

  &__title {
    font-size: 42px;
    margin-bottom: 2rem;
    letter-spacing: -0.05em;
    line-height: 1;
    font-weight: 300;
  }

  &__wrapper {
    display: grid;
    // align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__left {}

  &__right {
    font-size: 26px;
    p {

      margin-bottom: 2rem;
    }
  }
}

.container {}

.btn-reset {}

.btn {}
