.modals {
  &__container {}
}
.container {
}
.graph-modal {
  &__container {}
  &__close {}
  &__content {}
}
.btn-reset {
}
.js-modal-close {
}
.modal-form {

}
.form-group {

}
.btn {
}

.freeDates {
  p {
    text-align: center;
    font-size: 42px;
  }
}

@include mobile {
  .freeDates {
    p {
      text-align: center;
      font-size: 18px;
    }
  }

  .form-group {
    align-items: normal;
  }
}
