.weddings {
  &__container {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);
  }
  &__title {
    font-size: 42px;
    margin-bottom: 2rem;
    letter-spacing: -0.05em;
    line-height: 1;
    font-weight: 300;
  }
}
.container {
}
.swiper {
}
.weddingsSwiper {
}
.swiper-wrapper {
}
.swiper-slide {
}
.weddingsSlide {
  img {
    width: 100%;
    height: auto;
    // object-fit: cover;
    display: block;
  }
}
.swiper-button-next {
}
.swiper-button-prev {
}
.swiper-pagination {
  color: var(--background-color);
}
