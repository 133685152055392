.hero-video {
  position: relative;

  &__wrapper {
    height: 100vh;

    @media (max-width:1433px) {
      // height: calc(100vh - 106px);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: linear-gradient(180deg, rgba(0,0,0,0.2903536414565826) 0%, rgba(0,0,0,0.5032387955182073) 100%);
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 3;
    }
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;


  }
}

.hero {
  &__content {
    text-align: center;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 100%;
    transform: translate(50%, -50%);

    z-index: 3;
    color: var(--background-color);
  }

  &__title {
    display: block;
    font-size: 68px;
    // width: 100%;
    line-height: 100%;
    margin-bottom: 2rem;
    font-weight: 400;
  }

  &__subtitle {
    font-size: 42px;
    font-style: italic;
  }
}
