// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: 'Playfair', serif;
  --content-width: 1700px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --background-color: #e7e4df;
  --accent-color: #283f2b;

  //paddings
  --padding-vertical: 6.25rem;

}
