html {
  box-sizing: border-box;

}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family);
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}



.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: var(--background-color);
  color: var(--accent-color);

}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

a {
  text-decoration: none;
}

.site-container {
  // overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;
  // height: 100vh;
}

// section {
//   height: 100vh;
//   // width: 100%;
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   scroll-snap-align: start;
// }

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);

  // padding-left: 5.5555555556vw;
  // padding-right: 5.5555555556vw;

  // @include small-tablet {
  //   padding-left: 6.9230769231vw;
  //   padding-right: 6.9230769231vw;
  // }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.btn {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--background-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 300;
  border-radius: .25rem;
  padding: 30px 45px;
  // min-width: 340px;
  width: auto;
  text-align: center;
  font-size: 16px;
}

p {
  margin: 0;
}


.swiper-button-next::after,
.swiper-button-prev::after {
  color: var(--background-color);
}

.burger {
  color: var(--accent-color) !important;
  @include burger;
  margin-left: auto;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  @media (min-width:1434px) {
    display: none;
  }
}

@include tablet {
  :root {
    --padding-vertical: 3.125rem;
  }

}

@include small-tablet {
  :root {
    --padding-vertical: 1.563rem;
  }
}
