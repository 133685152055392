.footer {
  background-color: hsla(39, 17%, 80%, .3);

  &__container {
    padding-top: 3.813rem;
    padding-bottom: 3.813rem;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__col {
    h3 {
      margin-bottom: 2rem;
    }

    address {
      // font-size: 3.3vw;
      font-style: normal;
    }

  }

  &__name {
    margin-bottom: 1rem;
  }
  &__col:not(:last-child) {

    @include mobile {
      padding-bottom: 2rem;
      border-bottom: 1px dotted var(--accent-color);
    }
  }


  &__list {}

  &__item {

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

  }

  &__link {
    color: var(--accent-color);
    padding: 10px 0;
  }
}
