.container {
  &__about {
    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}

.about {
  &__col {
    font-size: 26px;
  }

  &__text:not(:last-child) {
    margin-bottom: 1rem;
  }
}
